

var ExcelLib = (function () {
  return {
    fnExcelReport:
      function (fileName, idTable) {
        var tab_text = "";
        tab_text += '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">'
        tab_text += '<head>'
        tab_text += '<!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>' + fileName + '</x:Name><x:WorksheetOptions><x:DisplayGridlines /></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->'
        tab_text += '<meta http-equiv="content-type" content="pplication/vnd.ms-excel; charset=UTF-8"></head>';
        tab_text += "<body>";
        tab_text += "<table border='2px solid #ccc'>";
        var textRange; var j = 0;
        var tab = document.getElementById(idTable);
        if (tab.tagName !== 'TABLE') {
          tab = tab.querySelector('table');
        }
        //tab = document.getElementById(idTable).querySelector('table'); // id of table
        var header = tab.rows[0];

        // Tìm các cột cần loại bỏ
        var columnsToRemove = [];
        for (var i = 0; i < header.cells.length; i++) {
          var cell = header.cells[i];
          if (cell.id === 'reject' || cell.classList.contains('reject')) {
            columnsToRemove.push(i);
          }
        }

        // Loại bỏ các cột từ dưới lên để tránh thay đổi chỉ mục khi xóa
        for (var i = columnsToRemove.length - 1; i >= 0; i--) {
          var colIndex = columnsToRemove[i];
          // Loại bỏ ô ở tất cả các hàng
          for (var row = 0; row < tab.rows.length; row++) {
            tab.rows[row].deleteCell(colIndex);
          }
        }
        for (j = 0; j < tab.rows.length; j++) {
          var innerHTMLRow = tab.rows[j].innerHTML;
          innerHTMLRow = innerHTMLRow.replace(/<select[^>]*>|<\/select>/gi, "");
          innerHTMLRow = innerHTMLRow.replace(/<button[^>]*>|<\/button>/gi, "");
          innerHTMLRow = innerHTMLRow.replace(/<svg[^>]*>|<\/svg>/gi, "");
          innerHTMLRow = innerHTMLRow.replace(/<path[^>]*>|<\/path>/gi, "");
          innerHTMLRow = innerHTMLRow.replace(/<i[^>]*>|<\/i>/gi, "");
          innerHTMLRow = innerHTMLRow.replace(/<input[^>]*>|<\/input>/gi, "");

          tab_text = tab_text + "<tr>" + innerHTMLRow + "</tr>";
          //tab_text=tab_text+"</tr>";
        }

        tab_text = tab_text + "</table>";
        tab_text = tab_text + "</body></html>";
        tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, "");//xóa thẻ <a> và </a> trong tab_text nếu bạn không muốn có liên kết trong bảng
        tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // xóa thẻ <img> trong tab_text nếu bạn không muốn có hình ảnh trong bảng
        tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // xóa thẻ <input> trong tab_text

        tab_text = tab_text.replace(/<!--[^>]*-->/g, ""); // Xóa các comment HTML
        tab_text = tab_text.replace(/<!--bindings[^>]*>|<\/bindings-->/gi, ""); //xóa thẻ <!--bindings với các phần tử attr bên trong thẻ <td> trong tab_text
        tab_text = tab_text.replace(/<!--container-->/gi, "");//xóa thẻ <!--container--> trong tab_text
        // //xóa attr _ngcontent với đuôi bất kỳ với các bất kỳ giá trị trong tab_text không ảnh hưởng attr khác
        // tab_text = tab_text.replace(/_ngcontent[^"]*"/gi, "");
        // //xóa attr ng-reflect-nz-left với các bất kỳ giá trị trong tab_text
        // tab_text = tab_text.replace(/ng-reflect-nz-left="[^"]*"/gi, "");
        // //xóa attr ng-reflect-ng-class với các bất kỳ giá trị trong tab_text
        // tab_text = tab_text.replace(/ng-reflect-ng-class="[^"]*"/gi, "");
        // //xóa attr ng-reflect-nz-left với các bất kỳ giá trị trong tab_text
        // tab_text = tab_text.replace(/ng-reflect-nz-right="[^"]*"/gi, "");
        // //xóa attr ng-reflect-nz-column-key với các bất kỳ giá trị trong tab_text
        // tab_text = tab_text.replace(/ng-reflect-nz-column-key="[^"]*"/gi, "");
        // //xóa attr nzright với các bất kỳ giá trị trong tab_text
        // tab_text = tab_text.replace(/nzright="[^"]*"/gi, "");
        // //xóa attr nzleft với các bất kỳ giá trị trong tab_text
        // tab_text = tab_text.replace(/nzleft="[^"]*"/gi, "");
        // //xóa attr nzwidth với các bất kỳ giá trị trong tab_text
        // tab_text = tab_text.replace(/nzwidth="[^"]*"/gi, "");
        //xóa combobox trong tab_text
        tab_text = tab_text.replace(/<select[^>]*>|<\/select>/gi, "");
        //xóa button trong tab_text
        tab_text = tab_text.replace(/<button[^>]*>|<\/button>/gi, "");
        //xóa thẻ svg trong tab_text
        tab_text = tab_text.replace(/<svg[^>]*>|<\/svg>/gi, "");
        //xóa thẻ path trong tab_text
        tab_text = tab_text.replace(/<path[^>]*>|<\/path>/gi, "");
        //xóa thẻ i trong tab_text
        tab_text = tab_text.replace(/<i[^>]*>|<\/i>/gi, "");
        //xóa thuộc tính !important trong style của các thẻ trong tab_text
        tab_text = tab_text.replace(/!important/gi, "");

        // Add column formatting
        tab_text = tab_text.replace(/<td/gi, "<td style='mso-number-format:\"@\"'");

        //console.log(tab_text);

        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))      // If Internet Explorer
        {
          txtArea1.document.open("txt/html", "replace");
          txtArea1.document.write(tab_text);
          txtArea1.document.close();
          txtArea1.focus();
          sa = txtArea1.document.execCommand("SaveAs", true, fileName);
          return (sa);
        }
        else {
          //other browser not tested on IE 11
          //sa = window.open('data:application/vnd.ms-excel,' + encodeURIComponent(tab_text));

          var pom = document.createElement('a');
          pom.setAttribute('href', 'data:application/vnd.ms-excel;charset=UTF-8,' + encodeURIComponent(tab_text));
          pom.setAttribute('download', fileName);
          if (document.createEvent) {
            var event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            pom.dispatchEvent(event);
          }
          else {
            pom.click();
          }
        }
      }

  }
})(ExcelLib || {})






